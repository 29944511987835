import Image from 'next/future/image';
import Link from 'next/link';
import mobileLeft from '@/public/images/homepage/back-to-school/back-to-school-left-mobile.png';
import mobileRight from '@/public/images/homepage/back-to-school/back-to-school-right-mobile.png';
import desktopLeft from '@/public/images/homepage/back-to-school/back-to-school-left-desktop.png';
import desktopRight from '@/public/images/homepage/back-to-school/back-to-school-right-desktop.png';
import highlightVector from '@/public/images/homepage/back-to-school/highlight.png';
import settings from '@/settings';

const BackToSchool = () => {
  const generateCollectionPageUrl = () => {
    if (settings.store === 'reebelo-nz')
      return '/collections/back-to-school-supplies';

    return '/collections/back-to-school-essentials';
  };

  const collectionPageUrl = generateCollectionPageUrl();

  return (
    <Link href={collectionPageUrl} prefetch>
      <a
        className="relative flex h-full w-screen
   flex-col items-center justify-center overflow-hidden bg-teal-500 p-5 text-gray-700"
        // eslint-disable-next-line react/forbid-dom-props
        style={{
          backgroundImage:
            "url('/images/homepage/back-to-school/back-to-school-grid.svg')",
        }}
      >
        {/* Mobile */}
        <div className="absolute h-full w-full md:hidden">
          <div className="absolute left-[-60px] h-full xxs:left-[-30px] sm:left-0">
            <Image
              src={mobileLeft}
              height={500}
              width={1500}
              className="mx-auto h-full w-auto object-cover"
              alt="Electronic Devices"
              loading="eager"
              quality={100}
            />
          </div>
          <div className="absolute right-[-60px] h-full xxs:right-[-30px] sm:right-0">
            <Image
              src={mobileRight}
              height={500}
              width={1500}
              className="mx-auto h-full w-auto object-cover"
              alt="Electronic Devices"
              loading="eager"
              quality={100}
            />
          </div>
        </div>
        {/* Desktop */}
        <div className="absolute hidden h-full w-full md:block">
          <div className="absolute left-[-250px] h-full lg:left-[-100px] xl:left-0">
            <Image
              src={desktopLeft}
              height={500}
              width={1500}
              className="mx-auto h-full w-auto object-cover"
              alt="Electronic Devices"
              loading="eager"
              quality={100}
            />
          </div>
          <div className="absolute right-[-250px] h-full lg:right-[-100px] xl:right-0">
            <Image
              src={desktopRight}
              height={500}
              width={1500}
              className="mx-auto h-full w-auto object-cover"
              alt="Electronic Devices"
              loading="eager"
              quality={100}
            />
          </div>
        </div>
        <h1
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="font-title relative text-center font-black uppercase"
        >
          <div className="text-[14px] leading-5 xs:text-[16.5px] sm:text-[20px] xl:text-[22.5px]">
            Study Smart.{' '}
            <div className="relative xs:inline">
              <div className="absolute top-1/3 left-1/2 z-0  w-[90%] -translate-x-1/2 -translate-y-1/2">
                <Image
                  src={highlightVector}
                  height={500}
                  width={1500}
                  className="h-full w-full object-contain"
                  alt="Electronic Devices"
                  loading="eager"
                  quality={100}
                />
              </div>
              <span className="relative">Shop Smarter.</span>
            </div>
          </div>
          <div
            className="relative mt-1 text-[27px] leading-8 text-white xs:text-3xl sm:mt-2.5 sm:text-4xl xl:text-[40px]"
            // eslint-disable-next-line react/forbid-dom-props
            style={{
              textShadow: '3px 3px 0px rgba(0, 0, 0, 1)',
              WebkitTextStroke: '1px black', // Adjust the width and color as needed
            }}
          >
            Back to <br className="xs:hidden" />
            School
          </div>
        </h1>
        <div className="mt-5 flex h-10 items-center justify-center rounded-full border-2 border-gray-700 !bg-gray-700 px-5 text-xs font-semibold text-white">
          Shop Deals
        </div>
      </a>
    </Link>
  );
};

export default BackToSchool;
