import dynamic from 'next/dynamic';
import BackToSchool from './Themes/BackToSchool';
import settings from '@/settings';

const DefaultHero = dynamic(() => import('./Themes/DefaultHero'), {
  ssr: true,
});

const Main = () => {
  if (['reebelo-dev', 'reebelo-au', 'reebelo-nz'].includes(settings.store))
    return <BackToSchool />;

  return <DefaultHero />;
};

export default Main;
