import {
  ReebeloConditions,
  ReebeloLanguage,
  TagHelpers,
  REEBELO_STORE_TO_LANGUAGE,
  RC_DURATION,
} from '@lambda/reebelo';
import { convertCellularFieldsToServiceProvider } from '@lambda/business/offers/cellular';
import { extractTags } from '@lambda/reebelo/src/tagHelpers';
import { get as _get, groupBy, pick, pickBy, sortBy, uniqBy } from 'lodash-es';
import {
  Connectivities,
  PimProductT,
  SimSlot,
} from '@lambda/apis/src/dynamoDb/types';
import { SearchOffers } from '@lambda/commons/apis/elasticsearch/types';
import { WarrantyT } from '@lambda/apis/src/warranty/types';
import settings from '@/settings';
import { CollectionData } from '@/settings/types';
import { logger } from '@/lib/logger';
import { DynamicVariantGroup } from '@/components/collections/[handle]/NewProductPage/types';

export const isNaStore =
  settings.store === 'reebelo-us' || settings.store === 'reebelo-ca';
export const SimSlotSkus: Record<ReebeloLanguage, Record<SimSlot, string>> = {
  en: {
    DS: 'Dual Sim',
    SS: 'Single Sim',
    ES: 'Dual eSim',
    SSES: 'Single Sim + eSim',
  },
  ko: {
    DS: '듀얼 SIM',
    SS: '단일 SIM',
    ES: 'Dual eSIM',
    SSES: '단일 SIM + eSIM',
  },
  zh: {
    DS: '雙卡',
    SS: '單卡',
    ES: 'Dual eSIM',
    SSES: '單卡 + eSIM',
  },
};

export const Conditions: Record<
  ReebeloLanguage,
  Record<ReebeloConditions, string>
> = {
  en: {
    'Brand New': 'Brand New',
    Premium: 'Premium',
    Pristine: 'Pristine',
    Excellent: 'Excellent',
    Good: 'Good',
    Acceptable: 'Acceptable',
  },
  ko: {
    'Brand New': '새상품',
    Premium: '프리미엄',
    Pristine: 'S급',
    Excellent: 'A급',
    Good: 'B급',
    Acceptable: 'C급',
  },
  zh: {
    'Brand New': '全新品',
    Premium: '優質',
    Pristine: '5星品',
    Excellent: '4.5星品',
    Good: '4星品',
    Acceptable: '3.5星品',
  },
};

export const ConnectivitieSkus: Record<
  ReebeloLanguage,
  Record<Connectivities, string>
> = {
  en: {
    C: 'Cellular',
    W: 'WiFi',
    CW: 'Cellular + WiFi',
    '4G': '4G',
    '5G': '5G',
    G: 'GPS',
    CG: 'Cellular + GPS',
    B: 'Bluetooth',
    BL: 'Bluetooth + LTE',
  },
  ko: {
    C: '모바일 네트워크',
    W: 'WiFi',
    CW: '모바일 네트워크 + Wifi',
    '4G': '4G',
    '5G': '5G',
    G: 'GPS',
    CG: 'Cellular + GPS',
    B: 'Bluetooth',
    BL: 'Bluetooth + LTE',
  },
  zh: {
    C: '行動網路',
    W: 'WiFi',
    CW: '行動網路 + Wifi',
    '4G': '4G',
    '5G': '5G',
    G: 'GPS',
    CG: 'Cellular + GPS',
    B: 'Bluetooth',
    BL: 'Bluetooth + LTE',
  },
};

type VariantNames =
  | 'serviceProvider'
  | 'screenSize'
  | 'color'
  | 'storage'
  | 'condition'
  | 'cpu'
  | 'ram'
  | 'simSlot'
  | 'connectivity'
  | 'watchFaceSize'
  | 'watchCaseMaterial'
  | 'watchCaseColor'
  | 'watchBandType'
  | 'watchBandColor'
  | 'watchBandMaterial'
  | 'charger'
  | 'touchbar'
  | 'version'
  | 'language';

export const VARIANTS: VariantNames[] = [
  'serviceProvider',
  'screenSize',
  'connectivity',
  'storage',
  'color',
  'cpu',
  'ram',
  'simSlot',
  'charger',
  'touchbar',
  'version',
  'language',
  'condition',
];
const SMARTWATCHVARIANTS: VariantNames[] = [
  'watchCaseMaterial',
  'watchFaceSize',
  'watchCaseColor',
  'connectivity',
  'watchBandMaterial',
  'watchBandType',
  'watchBandColor',
  'condition',
  'storage',
];

export type VariantObj = Record<VariantNames, string>;
export type SelectionT = Omit<Partial<VariantObj>, 'serviceProviders'> & {
  serviceProvider?: string;
};

export const tagToSelection = (
  tags: string[],
  dynamicVariants?: string[] | null,
): SelectionT => {
  const extracted = TagHelpers.extractTags(tags);
  const serviceProvider = convertCellularFieldsToServiceProvider(extracted);
  const variants =
    extracted.category === 'Smartwatches'
      ? SMARTWATCHVARIANTS
      : dynamicVariants ?? [...VARIANTS, 'batteryHealth'];
  const allObject = pick(
    { ...extracted, serviceProvider: serviceProvider?.[0] },
    variants,
  ) as VariantObj;

  return pickBy(allObject, (p) => p); // remove undefined values
};

export type ReplacedProduct = SearchOffers & {
  /** id of the more expensive offer that was replaced in the UI. if `null` then the offer was added for UI purpose. */
  replacedFromCondition?: ReebeloConditions;
  serviceProvider?: string;
  batteryUpgradePct?: string | null;
  batteryUpgradePrice?: string;
};

type ColorType = 'colors' | 'watchBandColors' | 'watchCaseColors';

const getLocaleColors = (
  product: PimProductT,
  language: ReebeloLanguage,
  colorType: ColorType,
): string[] =>
  Object.values(
    product.locale?.[language]?.[colorType] || product[colorType] || {},
  );

/**
 * Remove offers that don't have corresponding tags with the product.
 * and also offer that contains invalid service provider in US
 */
export const removeUnfitOffers = (
  offers: SearchOffers[],
  product: PimProductT,
) => {
  const language = REEBELO_STORE_TO_LANGUAGE[settings.store];

  const colors = getLocaleColors(product, language, 'colors');
  const simSlots = Object.values(product.simSlot || {}).map(
    (s) => SimSlotSkus[language][s],
  );
  const connectivities = product.connectivities.map(
    (c) => ConnectivitieSkus[language][c],
  );

  return offers
    .filter((o) => {
      const tags = extractTags(o.tags);
      let isValid = true;

      const compare = (key: keyof typeof tags, list?: string[]) => {
        if (
          list == null ||
          list.length === 0 ||
          list.includes(tags[key] as string)
        )
          return;
        isValid = false;

        logger.warn({ offer: o, product, issueWith: key }, 'Mapping issue');
      };

      if (o.categories && o.categories[0] === 'Smartwatches') {
        const bandColors = getLocaleColors(
          product,
          language,
          'watchBandColors',
        );
        const caseColors = getLocaleColors(
          product,
          language,
          'watchCaseColors',
        );

        compare('watchBandColor', bandColors);
        compare('watchBandType', product.watchBandTypes);
        compare('watchBandMaterial', product.watchBandMaterials);
        compare('watchCaseColor', caseColors);
        compare('watchCaseMaterial', product.watchCaseMaterials);
      } else {
        compare('simSlot', simSlots);
        compare('ram', product.rams);
        compare('cpu', product.cpus);
        compare('color', colors);
      }

      if (
        o.categories &&
        (o.categories.includes('Laptops') ||
          o.categories.includes('Desktop Computer'))
      )
        compare('screenSize', product.screenSizes);

      compare('storage', product.storages);
      compare('connectivity', connectivities);

      return isValid;
    })
    .filter((o) => {
      // skip this filter if not US store or there is no service providers
      if (
        settings.store !== 'reebelo-us' ||
        o.serviceProviders == null ||
        o.serviceProviders.length === 0
      )
        return true;

      let serviceProviders = o.serviceProviders ?? [];

      if (typeof serviceProviders === 'string')
        serviceProviders = JSON.parse(serviceProviders);

      return serviceProviders.some((provider) =>
        ['Unlocked', 'AT&T', 'T-Mobile', 'Verizon'].includes(provider),
      );
    });
};

export const generateAltTagForOffer = (
  offer: SearchOffers,
  productName: string,
): string => {
  if (!offer) return '';
  let altTag = productName;

  if (offer.categories?.includes('Laptops')) {
    if (offer.cpu) altTag += ` ${offer.cpu}`;
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Smartphones')) {
    if (offer.storage) altTag += ` ${offer.storage}`;
    if (offer.serviceProviders?.includes('Unlocked')) altTag += ` Unlocked`;
    else if (offer?.serviceProviders?.length)
      altTag += ` for ${offer.serviceProviders[0]}`;
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Tablets')) {
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Monitors')) {
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Gaming Consoles')) {
    if (offer.storage) altTag += ` ${offer.storage}`;
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Earphones')) {
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Smartwatches')) {
    if (offer.watchCaseMaterial) altTag += ` ${offer.watchCaseMaterial}`;
    if (offer.watchFaceSize) altTag += ` ${offer.watchFaceSize}`;
    if (offer.watchCaseColor) altTag += ` in ${offer.watchCaseColor}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  return altTag;
};

export const normalizeOffers = (
  offers: Array<SearchOffers>,
  collectionItem: CollectionData,
): Array<ReplacedProduct> => {
  const result: Array<ReplacedProduct> = [];

  offers.forEach((offer) => {
    if (offer.serviceProviders && offer.serviceProviders.length > 0) {
      const { ...offerWithoutServiceProviders } = offer;

      let serviceProviders = offer.serviceProviders ?? [];

      if (typeof serviceProviders === 'string')
        serviceProviders = JSON.parse(serviceProviders);

      serviceProviders.forEach((serviceProvider) => {
        result.push({
          ...offerWithoutServiceProviders,
          serviceProvider,
          defaultImageAltTag: generateAltTagForOffer(
            offer,
            `${collectionItem.title}`,
          ),
        });
      });
    } else {
      result.push({
        ...offer,
        defaultImageAltTag: generateAltTagForOffer(
          offer,
          `${collectionItem.title}`,
        ),
      });
    }
  });

  return result;
};

export const getDynamicVariants = (product: PimProductT) => {
  // Only releasing other categories as we move into dynamic variants
  const categoriesToExclude = [
    'Smartwatches',
    'Tablets',
    'Smartphones',
    'Laptops',
  ];

  const shouldShowDynamicVariants = !categoriesToExclude.includes(
    product.category,
  );

  if (!shouldShowDynamicVariants || !product.variants)
    return { dynamicVariantGroups: null, dynamicVariantGroupsNames: null };

  const dynamicVariantGroups = product.variants
    .map((variant) => {
      const variantOptions = product[
        variant.fieldName as keyof PimProductT
      ] as string[];

      if (!variantOptions || !Array.isArray(variantOptions)) return null;

      return {
        ...variant,
        variantOptions,
      };
    })
    .filter((group): group is DynamicVariantGroup => !!group);

  const dynamicVariantGroupsNames = dynamicVariantGroups
    ?.map((group) => group.name)
    .filter((name) => name !== 'batteryHealth')
    .concat('color', 'condition');
  // TODO: we have to hard code color & condition for now since they are special cases,
  // same for excluding battery health.

  return { dynamicVariantGroups, dynamicVariantGroupsNames };
};

/** Remove expensive offers, this comes from the fact that `product_is_cheapest` might tag several products with the same condition */
export const keepOnlyCheapestOffers = (
  offers: ReplacedProduct[],
  dynamicVariants?: string[] | null,
): ReplacedProduct[] => {
  // offers are expected to be sorted by cheapest first
  const offersWithKey = offers.map((offer) => {
    let variants: VariantNames | string[] = dynamicVariants ?? VARIANTS;

    if (offer && offer.categories && offer.categories[0] === 'Smartwatches')
      variants = SMARTWATCHVARIANTS;

    const key = variants
      .map((v) => offer[v as keyof ReplacedProduct])
      .join('#');

    return { key, offer, price: offer.price };
  });

  return uniqBy(sortBy(offersWithKey, 'price'), 'key').map((o) => o.offer);
};

/** Replace an offer if it exists is a better condition and at a cheaper price */
export const replaceExpensiveOffers = (
  offers: ReplacedProduct[],
  dynamicVariants?: string[] | null,
): ReplacedProduct[] => {
  const language = REEBELO_STORE_TO_LANGUAGE[settings.store];
  const RC = Conditions[language] as Record<
    ReebeloConditions,
    ReebeloConditions
  >;

  const groups = Object.values(
    groupBy(offers, (offer) => {
      let variants: VariantNames | string[] = dynamicVariants ?? VARIANTS;

      if (offer && offer.categories && offer.categories[0] === 'Smartwatches')
        variants = SMARTWATCHVARIANTS;

      return variants
        .filter((v) => v !== 'condition')
        .map((v) => offer[v as keyof ReplacedProduct])
        .join('#');
    }),
  );

  return groups.flatMap((group) => {
    const getWithCond = (cond: ReebeloConditions) =>
      group.find((p) => p.tags.includes(`Condition_${cond}`)) as
        | ReplacedProduct
        | undefined;

    const tagOfferAs = (
      offer: SearchOffers | undefined,
      cond: ReebeloConditions,
    ) =>
      offer == null
        ? undefined
        : {
            ...offer,
            tags: offer.tags
              .filter((t) => !t.startsWith('Condition_'))
              .concat(`Condition_${cond}`),
          };

    const isSmartphones = group.find((p) =>
      p.tags.includes(`Category_Smartphones`),
    );

    // Brand New
    const bnOffer = getWithCond(RC[ReebeloConditions.BrandNew]);

    // Premium
    let prOffer = getWithCond(RC[ReebeloConditions.Premium]);

    if (bnOffer && (!prOffer || (prOffer && prOffer.price >= bnOffer.price))) {
      prOffer = {
        replacedFromCondition: RC[ReebeloConditions.BrandNew],
        ...bnOffer,
      };
    }

    // Pristine
    let anOffer = getWithCond(RC[ReebeloConditions.Pristine]);

    if (prOffer && (!anOffer || (anOffer && anOffer.price >= prOffer.price))) {
      anOffer = {
        replacedFromCondition: RC[ReebeloConditions.Premium],
        ...prOffer,
      };
    }

    // Excellent
    let exOffer = getWithCond(RC[ReebeloConditions.Excellent]);

    if (anOffer && (!exOffer || (exOffer && exOffer.price >= anOffer.price))) {
      exOffer = {
        replacedFromCondition: RC[ReebeloConditions.Pristine],
        ...anOffer,
      };
    }

    // Very Good
    let vgOffer = getWithCond(RC[ReebeloConditions.Good]);

    if (exOffer && (!vgOffer || (vgOffer && vgOffer.price >= exOffer.price))) {
      vgOffer = {
        replacedFromCondition: RC[ReebeloConditions.Excellent],
        ...exOffer,
      };
    }

    // Acceptable
    let goOffer = getWithCond(RC[ReebeloConditions.Acceptable]);

    if (vgOffer && (!goOffer || (goOffer && goOffer.price >= vgOffer.price))) {
      goOffer = {
        replacedFromCondition: RC[ReebeloConditions.Good],
        ...vgOffer,
      };
    }

    return [
      isNaStore
        ? undefined
        : tagOfferAs(bnOffer, RC[ReebeloConditions.BrandNew]),
      tagOfferAs(prOffer, RC[ReebeloConditions.Premium]),
      tagOfferAs(anOffer, RC[ReebeloConditions.Pristine]),
      tagOfferAs(exOffer, RC[ReebeloConditions.Excellent]),
      isNaStore && !isSmartphones
        ? vgOffer
        : tagOfferAs(vgOffer, RC[ReebeloConditions.Good]),
      tagOfferAs(goOffer, RC[ReebeloConditions.Acceptable]),
    ].filter(Boolean) as ReplacedProduct[];
  });
};

export const selectWarranty = async (
  price: number,
  duration: RC_DURATION,
  itemCategory = 'default',
) => {
  try {
    const apiUrl = `/api/warranty/search-warranty-sku?price=${price}&category=${itemCategory}`;

    const response = await fetch(apiUrl);

    if (!response.ok) {
      logger.error(
        { response, price, itemCategory },
        `Error fetching warranties`,
      );

      return undefined;
    }

    const warranties = (await response.json()) as WarrantyT[];

    logger.info({ warranties }, 'Fetched warranties');

    if (!warranties || !warranties.length) return undefined;

    const warranty = warranties.find(
      (w) => w.duration === Number(duration) && w.warrantyType === 'Basic',
    );

    if (!warranty) return undefined;

    return {
      ...warranty,
      price: warranty.price / 100,
    };
  } catch (err) {
    logger.error({ err, itemCategory, price }, 'Error in selectWarranty');

    return undefined;
  }
};

export const selectionToString = (
  selection: SelectionT,
  category: string,
  dynamicVariants?: string[],
): string => {
  let variants: VariantNames | string[] = dynamicVariants ?? VARIANTS;

  if (category === 'Smartwatches') variants = SMARTWATCHVARIANTS;

  return variants
    .map((v) => selection[v as keyof SelectionT])
    .filter((str): str is string => str != null)
    .map((str) => str.toLocaleLowerCase().replace(/ +/g, '-'))
    .join('-');
};

type OfferTreeT = Record<string, ReplacedProduct>;

export class OfferTree {
  tree: OfferTreeT;

  orderedPath: string[];

  dynamicVariants?: string[] | null;

  private extraParams: Record<string, string>;

  constructor(
    offers: ReplacedProduct[],
    private category: string,
    dynamicVariantGroupsNames?: string[] | null,
    extraParams: { handle?: string } = {},
  ) {
    this.dynamicVariants = dynamicVariantGroupsNames;
    this.extraParams = extraParams;
    this.category = category;
    const pathToOffer: OfferTreeT = {};

    offers.forEach((offer) => {
      const tags = extractTags(offer.tags);
      const path = this.toPath({
        ...tags,
        serviceProvider: offer.serviceProvider,
      });

      pathToOffer[path] = offer;
    });
    this.tree = pathToOffer;
    this.orderedPath = Object.keys(pathToOffer).sort(
      (pathA, pathB) => pathToOffer[pathA].price - pathToOffer[pathB].price,
    );
  }

  filter({ serviceProvider }: { serviceProvider: string | undefined }) {
    // If no service provider is provided, return the current instance
    if (!serviceProvider) return this;

    // Filter the orderedPath based on the provided serviceProvider
    const filteredPath = this.orderedPath.filter((o) =>
      o.includes(serviceProvider),
    );

    // Create a new OfferTree instance with the filtered path
    const filteredOffers = filteredPath.map((path) => this.tree[path]);

    return new OfferTree(
      filteredOffers,
      this.category,
      this.dynamicVariants,
      this.extraParams,
    );
  }

  get(selection: Partial<VariantObj>) {
    const path = this.toPath(selection);
    const directGet = this.tree[path];

    if (directGet != null) return directGet;

    // If the path is 64GB.Blue.undefined.undefined.undefined.Good, we generate the follwing path:
    // [64GB.Blue.undefined.undefined.undefined, 64GB.Blue.undefined, 64GB.Blue, 64GB, '']
    const variants =
      this.category === 'Smartwatches'
        ? SMARTWATCHVARIANTS
        : this.dynamicVariants ?? VARIANTS;

    const candidatePaths = path
      .split(',')
      .map((_, index, arr) =>
        arr.slice(0, variants.length - index - 1).join(','),
      );

    const electedPath = candidatePaths.find((c) =>
      this.orderedPath.find((p) => p.startsWith(c)),
    ) as string; // can't be undefined since there are offers
    const potentialPaths = this.orderedPath.filter((p) =>
      p.startsWith(electedPath),
    );
    const sortedPath = sortBy(potentialPaths, (p) =>
      // path with the most common value with  the selection will be selected
      p
        .split(',')
        .map((k, i) => Number(k === path[i]))
        .reduce((acc, val) => acc + val),
    );

    return this.tree[sortedPath[0]];
  }

  getFrom(
    nextSelection: Partial<VariantObj>,
    currentSelection: Partial<VariantObj>,
    index?: number,
  ):
    | null
    | (SelectionT & { replacedFromCondition?: string; suggested?: boolean }) {
    const reg = /(,1?undefined)+$/g; // trim `undefined` at the end
    const nextPath = this.toPath(nextSelection).replace(reg, '');

    const isOutOfStock =
      this.orderedPath.find((o) =>
        o
          .replace(/Bluetooth \+ LTE/, 'LTE + Bluetooth')
          .startsWith(nextPath.replace(/Bluetooth \+ LTE/, 'LTE + Bluetooth')),
      ) == null;

    // If nextPath is set to "Stainless Steel.40mm.Gold.Bluetooth," and the orderedPath contains "Stainless Steel.40mm.Gold.Bluetooth + LTE.Leather.Leather Strap.Pink.1Acceptable.4GB," the current implementation returns false, which is incorrect. Therefore, modifing the string from "Bluetooth + LTE" to "LTE + Bluetooth" to ensure the correct result.

    const replacedSelection = { ...currentSelection, ...nextSelection };

    const replacedPath = this.toPath(replacedSelection).replace(reg, '');
    const cheapestMatchedPath = this.orderedPath.find((o) =>
      o.startsWith(replacedPath),
    );

    if (isOutOfStock) {
      const target = nextPath.split(',');
      let hasTargetPath;

      if (index) {
        hasTargetPath = this.orderedPath.find((path) => {
          const p = path.split(',');

          return p[index] === target[target.length - 1];
        });
      } else {
        hasTargetPath = this.orderedPath.find(
          (o) =>
            o.includes(`,${target[target.length - 1]},`) ||
            o.startsWith(`${target[target.length - 1]},`) ||
            o.endsWith(`,${target[target.length - 1]}`),
        );
      }

      if (hasTargetPath == null) return null;

      if (hasTargetPath) {
        const offer = _get(this.tree, hasTargetPath.replace(reg, ''));

        if (!offer) return null;

        return {
          ...this.extraParams,
          ...tagToSelection(offer.tags, this.dynamicVariants),
          serviceProvider: offer.serviceProvider,
          replacedFromCondition: offer.replacedFromCondition,
          suggested: true,
        };
      }
    }

    const offer =
      cheapestMatchedPath == null
        ? this.get(replacedSelection) // path is has been found
        : _get(this.tree, cheapestMatchedPath);

    if (offer == null) return null;

    return {
      ...this.extraParams,
      ...tagToSelection(offer.tags, this.dynamicVariants),
      serviceProvider: offer.serviceProvider,
      replacedFromCondition: offer.replacedFromCondition,
      suggested: false,
    };
  }

  /**
   * Convert a selection into JSON path like `64GB.Blue.undefined.undefined.undefined.1Good`
   */
  private toPath = (selection: Partial<VariantObj>) => {
    // condition is formated tobe sortable so in case of same price, we fallback on the best condition
    const SORTABLE_COND: Record<string, number> = {
      undefined: 1,
      [ReebeloConditions.Acceptable]: 1,
      [ReebeloConditions.Good]: 2,
      [ReebeloConditions.Excellent]: 3,
      [ReebeloConditions.Pristine]: 4,
      [ReebeloConditions.BrandNew]: 5,
    };
    const origCondition = selection.condition?.replace('Very Good', 'Good');
    const condition = `${
      SORTABLE_COND[origCondition || 'undefined']
    }${origCondition}`;
    const formatedSelection: Record<string, string> = {
      ...selection,
      condition,
    };

    if (this.category === 'Smartwatches') {
      return SMARTWATCHVARIANTS.map(
        (v) => formatedSelection[v] || 'undefined',
      ).join(',');
    }

    const variants = this.dynamicVariants ?? VARIANTS;

    return variants.map((v) => formatedSelection[v] || 'undefined').join(',');
  };
}

export const getBetterConditions = (condition?: ReebeloConditions) => {
  if (!condition) return '';
  const conditions = [
    ReebeloConditions.Acceptable,
    ReebeloConditions.Good,
    ReebeloConditions.Excellent,
    ReebeloConditions.Pristine,
    ReebeloConditions.Premium,
    ReebeloConditions.BrandNew,
  ];

  return conditions.slice(conditions.indexOf(condition) + 1).join(',');
};
