import cn from 'classnames';
import Section from '@/components/commons/Section';
import popularCategories from '@/settings/popular-categories';
import DesktopView from '@/components/commons/PopularCategories/DesktopView';
import MobileView from '@/components/commons/PopularCategories/MobileView';
import Carousel from '@/components/commons/PopularCategories/Carousel';

const PopularCategories = ({
  isHomePage,
  isCartPage,
  isThankYouPage,
  className,
  title = 'Popular Categories',
  subtitle,
}: {
  isHomePage?: boolean;
  isCartPage?: boolean;
  isThankYouPage?: boolean;
  className?: string;
  title?: string;
  subtitle?: string;
}) => {
  const getType = () => {
    if (isCartPage) return 'CART';
    if (isThankYouPage) return 'THANK_YOU';

    return 'DEFAULT';
  };

  const type = getType();

  return (
    <Section className={className}>
      <div className={cn({ 'reebelo-container': isHomePage })}>
        <h2
          className={cn('flex items-center', {
            'text-lg font-semibold md:text-xl': !isCartPage,
            'text-base font-bold': isCartPage,
          })}
        >
          {title}
        </h2>
        {subtitle && <p className="text-gray-700/75">{subtitle}</p>}
        {isHomePage ? (
          <>
            <DesktopView categories={popularCategories} />
            <MobileView categories={popularCategories} />
          </>
        ) : (
          <Carousel
            popularCategories={popularCategories}
            small={isCartPage || isThankYouPage}
            type={type}
          />
        )}
      </div>
    </Section>
  );
};

export default PopularCategories;
