import { TypesTimeZones } from '@zerodep/types.timezones';

export type ReebeloB2bStoreT = 'reebelo-b2b-dev' | 'reebelo-b2b';
export type ReebeloStoreT =
  | 'reebelo-dev'
  | 'reebelo-au'
  | 'reebelo-kr'
  | 'reebelo-nz'
  | 'quista'
  | 'reebelo-tw'
  | 'reebelo-us'
  | 'reebelo-ca';

export enum ReebeloB2bConditions {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}
// Good & Acceptable overlap
export enum ReebeloConditions {
  BrandNew = 'Brand New',
  Premium = 'Premium',
  Pristine = 'Pristine',
  Excellent = 'Excellent',
  Good = 'Good',
  Acceptable = 'Acceptable',
}

type ReebeloMappingConditions =
  | ReebeloConditions.BrandNew
  | ReebeloConditions.Premium
  | ReebeloConditions.Pristine
  | ReebeloConditions.Excellent
  | ReebeloConditions.Good
  | ReebeloConditions.Acceptable;

export const ReebeloConditionsShortHand: Record<
  ReebeloMappingConditions,
  string
> = {
  [ReebeloConditions.BrandNew]: '1',
  [ReebeloConditions.Premium]: '2',
  [ReebeloConditions.Pristine]: '3',
  [ReebeloConditions.Excellent]: '4',
  [ReebeloConditions.Good]: '5',
  [ReebeloConditions.Acceptable]: '6',
};
export const ReebeloConditionsShortHandReverse: Record<
  string,
  ReebeloMappingConditions
> = {
  '1': ReebeloConditions.BrandNew,
  '2': ReebeloConditions.Premium,
  '3': ReebeloConditions.Pristine,
  '4': ReebeloConditions.Excellent,
  '5': ReebeloConditions.Good,
  '6': ReebeloConditions.Acceptable,
};
// We keep this for subsequent stores
export const REEBELO_CONDITIONS = (store: ReebeloStoreT) =>
  ['reebelo-dev'].includes(store)
    ? [
        ReebeloConditions.BrandNew,
        ReebeloConditions.Premium,
        ReebeloConditions.Excellent,
        ReebeloConditions.Acceptable,
      ]
    : [
        ReebeloConditions.BrandNew,
        ReebeloConditions.Premium,
        ReebeloConditions.Excellent,
        ReebeloConditions.Good,
        ReebeloConditions.Acceptable,
      ];
export const REEBELO_B2B_STORES: ReebeloB2bStoreT[] =
  process.env.STAGE === 'prod' || process.env.STAGE === 'test'
    ? ['reebelo-b2b']
    : ['reebelo-b2b-dev'];

export const REEBELO_STORES: ReebeloStoreT[] =
  process.env.STAGE === 'prod' || process.env.STAGE === 'test'
    ? [
        'quista',
        'reebelo-au',
        'reebelo-nz',
        'reebelo-kr',
        'reebelo-tw',
        'reebelo-us',
        'reebelo-ca',
      ]
    : ['reebelo-dev'];

const REEBELO_BLACKLISTED_STORES = ['reebelo-kr', 'reebelo-tw'];

export const REEBELO_LIVE_STORES = REEBELO_STORES.filter(
  (store) => !REEBELO_BLACKLISTED_STORES.includes(store),
);

export type ReebeloCurrencies =
  | 'AUD'
  | 'HKD'
  | 'MYR'
  | 'SGD'
  | 'NZD'
  | 'TWD'
  | 'KRW'
  | 'USD'
  | 'CAD';
export const REEBELO_CURRENCY: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  ReebeloCurrencies
> = {
  'reebelo-au': 'AUD',
  'reebelo-nz': 'NZD',
  quista: 'SGD',
  'reebelo-tw': 'TWD',
  'reebelo-dev': 'SGD',
  'reebelo-kr': 'KRW',
  'reebelo-us': 'USD',
  'reebelo-ca': 'CAD',
  'reebelo-b2b': 'USD',
  'reebelo-b2b-dev': 'USD',
};

export const REEBELO_COUNTRY_CODE: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  string
> = {
  'reebelo-au': 'AU',
  'reebelo-nz': 'NZ',
  quista: 'SG',
  'reebelo-dev': 'DEV',
  'reebelo-tw': 'TW',
  'reebelo-kr': 'KR',
  'reebelo-us': 'US',
  'reebelo-ca': 'CA',
  'reebelo-b2b': 'B2B',
  'reebelo-b2b-dev': 'B2B-DEV',
};

export const REEBELO_COUNTRY_TO_MARKET_MAPPINGS = {
  SG: 'quista',
  US: 'reebelo-us',
  AU: 'reebelo-au',
  DEV: 'reebelo-dev',
  NZ: 'reebelo-nz',
  CA: 'reebelo-ca',
};
export const REEBELO_COUNTRY: Record<ReebeloStoreT | ReebeloB2bStoreT, string> =
  {
    'reebelo-au': 'Australia',
    'reebelo-nz': 'New Zealand',
    quista: 'Singapore',
    'reebelo-dev': 'Singapore',
    'reebelo-tw': 'Taiwan',
    'reebelo-kr': 'Korea',
    'reebelo-us': 'United States of America',
    'reebelo-ca': 'Canada',
    'reebelo-b2b': 'Reebelo B2B',
    'reebelo-b2b-dev': 'Reebelo B2B',
  };
export const REEBELO_DOMAINS: Record<ReebeloStoreT | ReebeloB2bStoreT, string> =
  {
    'reebelo-au':
      process.env.STAGE !== 'prod' ? 'au.reebelo.blue' : 'reebelo.com.au',
    quista: process.env.STAGE !== 'prod' ? 'sg.reebelo.blue' : 'reebelo.sg',
    'reebelo-nz':
      process.env.STAGE !== 'prod' ? 'nz.reebelo.blue' : 'reebelo.co.nz',
    'reebelo-tw': 'reebelo.com.tw',
    'reebelo-kr': 'reebelo.co.kr',
    'reebelo-dev': 'reebelo.blue',
    'reebelo-us':
      process.env.STAGE !== 'prod' ? 'us.reebelo.blue' : 'reebelo.com',
    'reebelo-ca':
      process.env.STAGE !== 'prod' ? 'ca.reebelo.blue' : 'reebelo.ca',
    'reebelo-b2b': 'reebelo-wholesale.com',
    'reebelo-b2b-dev': 'b2b.reebelo.blue',
  };

export const REEBELO_TZ_NAME: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  TypesTimeZones
> = {
  'reebelo-au': 'Australia/Melbourne',
  quista: 'Asia/Singapore',
  'reebelo-nz': 'Pacific/Auckland',
  'reebelo-tw': 'Asia/Singapore',
  'reebelo-dev': 'Asia/Singapore',
  'reebelo-kr': 'Asia/Seoul',
  'reebelo-us': 'America/Los_Angeles',
  'reebelo-ca': 'America/Toronto',
  'reebelo-b2b': 'America/Los_Angeles',
  'reebelo-b2b-dev': 'America/Yellowknife', // 'America/Los_Angeles' revert back on 12 Mar 2023
};

export const TIMEZONE_ABBREVIATIONS: Record<string, string> = {
  'Pacific/Auckland': 'NZDT',
  'Australia/Melbourne': 'AEST',
  'Asia/Singapore': 'SGT',
  'Asia/Seoul': 'KST',
  'America/Yellowknife': 'PDT',
  'America/Toronto': 'EDT',
  'Pacific/Fiji': 'NZST',
  'Australia/Brisbane': 'AEST',
  'America/Los_Angeles': 'PDT',
};

// Can be extended to be 'kr' | 'zh' ...etc
// https://www.loc.gov/standards/iso639-2/php/code_list.php
export type ReebeloLanguage = 'en' | 'ko' | 'zh';
export const REEBELO_STORE_TO_LANGUAGE: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  ReebeloLanguage
> = {
  'reebelo-au': 'en',
  quista: 'en',
  'reebelo-nz': 'en',
  'reebelo-tw': 'zh',
  'reebelo-dev': 'en',
  'reebelo-kr': 'ko',
  'reebelo-us': 'en',
  'reebelo-ca': 'en',
  'reebelo-b2b': 'en',
  'reebelo-b2b-dev': 'en',
};
export const REEBELO_LANGUAGE_LONG: Record<ReebeloLanguage, string> = {
  en: 'English',
  ko: 'Korean',
  zh: 'Chinese',
};

export const REEBELO_STORE_TO_LOCALE: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  string
> = {
  'reebelo-us': 'en-US',
  'reebelo-au': 'en-AU',
  'reebelo-nz': 'en-NZ',
  quista: 'en-SG',
  'reebelo-tw': 'zh-TW',
  'reebelo-dev': 'en-SG',
  'reebelo-kr': 'ko',
  'reebelo-ca': 'en-CA',
  'reebelo-b2b': 'en-US',
  'reebelo-b2b-dev': 'en-US',
};

export const REEBELO_STORE_LOCATION_ID: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  number
> = {
  'reebelo-dev': 52150796454,
  quista: 31460884535,
  'reebelo-au': 49451335829,
  'reebelo-nz': 60921118876,
  'reebelo-kr': 63234015325,
  'reebelo-tw': 64223445150,
  'reebelo-us': 66716532919,
  'reebelo-ca': 86033301806,
  'reebelo-b2b': 84536656169,
  'reebelo-b2b-dev': 88012751143,
};

export const REEBELO_CANADA_LOCATION_ID: Record<
  'CROSS_BORDER' | 'LOCAL',
  number
> =
  process.env.STAGE === 'prod'
    ? { LOCAL: 96020889902, CROSS_BORDER: 96020922670 }
    : {
        LOCAL: 70209110213,
        CROSS_BORDER: 70209175749,
      };

export const REEBELO_STORES_MULTI_LOCATIONS: ReebeloStoreT[] = [
  'reebelo-dev',
  'reebelo-ca',
];

export const REEBELO_STORES_WITH_CARRIER: ReebeloStoreT[] = [
  'reebelo-dev',
  'reebelo-us',
];

export const ORDER_PREFIX: Record<ReebeloStoreT | ReebeloB2bStoreT, string> = {
  quista: '#',
  'reebelo-au': '#A',
  'reebelo-tw': '#TW',
  'reebelo-nz': '#NZ',
  'reebelo-kr': '#KR',
  'reebelo-us': '#US',
  'reebelo-ca': '#CA',
  'reebelo-dev': '#',
  'reebelo-b2b': '#B2B',
  'reebelo-b2b-dev': '#B2B',
};

// MsCheckout has it's own API keys and therefore it's own Shopify sales channel.
const MS_CHECKOUT_SALES_CHANNEL = 'MsCheckout';

export const REEBELO_STORE_TO_SALES_CHANNELS: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  string[]
> = {
  'reebelo-au': ['Online Store', 'Storefront v2', MS_CHECKOUT_SALES_CHANNEL],
  'reebelo-nz': ['Online Store', 'Storefront v2', MS_CHECKOUT_SALES_CHANNEL],
  quista: ['Online Store', 'Storefront V2', MS_CHECKOUT_SALES_CHANNEL], // intentionally upper case the v2 to match storefront app name on Shopify
  'reebelo-tw': ['Online Store'],
  'reebelo-dev': ['Online Store', 'Storefront v2', MS_CHECKOUT_SALES_CHANNEL],
  'reebelo-kr': ['Online Store', 'Storefront v2'],
  'reebelo-us': ['Online Store', 'Storefront v2', MS_CHECKOUT_SALES_CHANNEL],
  'reebelo-ca': ['Online Store', 'Storefront V2', MS_CHECKOUT_SALES_CHANNEL],
  'reebelo-b2b': ['Online Store', 'Storefront V2'],
  'reebelo-b2b-dev': ['Online Store', 'Storefront V2'],
};

export const REEBELO_US_SERVICE_PROVIDERS: string[] = [
  'Unlocked',
  'AT&T',
  'Verizon',
  'T-Mobile',
];

export enum ReebeloGtinRegion {
  APAC = 'Apac',
  USA = 'Usa',
  CA = 'Canada',
}
export const REEBELO_STORES_TO_REGIONS: Record<
  ReebeloStoreT | ReebeloB2bStoreT,
  ReebeloGtinRegion
> = {
  'reebelo-dev': ReebeloGtinRegion.USA,
  'reebelo-au': ReebeloGtinRegion.APAC,
  'reebelo-nz': ReebeloGtinRegion.APAC,
  quista: ReebeloGtinRegion.APAC,
  'reebelo-kr': ReebeloGtinRegion.APAC,
  'reebelo-tw': ReebeloGtinRegion.APAC,
  'reebelo-us': ReebeloGtinRegion.USA,
  'reebelo-ca': ReebeloGtinRegion.CA,
  'reebelo-b2b': ReebeloGtinRegion.USA,
  'reebelo-b2b-dev': ReebeloGtinRegion.USA,
};

export enum ReebeloVendorsInvetoryOrigins {
  HONG_KONG = 'Hong Kong',
  NEW_ZEALAND = 'New Zealand',
  AUSTRALIA = 'Australia',
  USA = 'United States',
  SINGAPORE = 'Singapore',
  TAIWAN = 'Taiwan',
  KOREA = 'Korea',
  CANADA = 'Canada',
}

export const REEBELO_STORE_TO_INVENTORY_ORIGIN: Record<
  ReebeloStoreT,
  ReebeloVendorsInvetoryOrigins
> = {
  'reebelo-au': ReebeloVendorsInvetoryOrigins.AUSTRALIA,
  'reebelo-nz': ReebeloVendorsInvetoryOrigins.NEW_ZEALAND,
  'reebelo-dev': ReebeloVendorsInvetoryOrigins.SINGAPORE,
  'reebelo-tw': ReebeloVendorsInvetoryOrigins.TAIWAN,
  'reebelo-kr': ReebeloVendorsInvetoryOrigins.KOREA,
  'reebelo-us': ReebeloVendorsInvetoryOrigins.USA,
  'reebelo-ca': ReebeloVendorsInvetoryOrigins.CANADA,
  quista: ReebeloVendorsInvetoryOrigins.SINGAPORE,
};

// Only used for returns atm
export const REEBELO_COUNTRY_CODE_TO_STATES: Record<string, Array<string>> = {
  AU: ['NSW', 'VIC', 'ACT', 'QLD', 'SA', 'WA', 'TAS', 'NT'],
  NZ: [
    'Auckland',
    'Bay of Plenty',
    'Canterbury',
    'Christchurch',
    'Chatham Islands Territory',
    'Gisborne',
    'Greater Wellington',
    "Hawke's Bay",
    'Manawatū-Whanganui',
    'Marlborough',
    'Nelson',
    'Northland',
    'Ngahinapouri',
    'Otago',
    'Paihia',
    'Southland',
    'Taranaki',
    'Tauranga',
    'Tasman',
    'Waikato',
    'West Coast',
  ],
  SG: ['SG'],
  DEV: ['SG'],
  US: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  CA: [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NT',
    'NS',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
  ],
};
export const REEBELO_COUNTRY_TAXES: Record<string, number> = {
  AU: 1.1,
  SG: 1,
  NZ: 1.15,
  KR: 1.1,
  TW: 1.05,
};
export const REEBELO_CROSS_BORDER_TAXES: Record<string, number> = {
  AU: 11,
  NZ: 7.666,
};

export const REEBELO_SALES_THRESHOLD: Record<string, number> = {
  AU: 1099.99,
  NZ: 1149.99,
};

export const REEBELO_STORES_WITHOUT_GST: Array<ReebeloStoreT> = [
  'reebelo-us',
  'reebelo-ca',
];

export const REEBELO_SALES_CHANNEL_PREFIX = 'SALES_CHANNEL';
export const REEBELO_EXTERNAL_ORDER_ID_PREFIX = 'OID';
export const REEBELO_EXTERNAL_PURCHASE_ORDER_NUMBER_PREFIX = 'PON';
export enum ReebeloSalesChannelT {
  REEBELO = 'Reebelo',
  WALMART = 'Walmart',
  BUYBACK = 'Buyback',
  WISH = 'Wish',
  MERCARI = 'Mercari',
  TIKTOK = 'Tiktok',
  AMAZON = 'Amazon',
}
export const REEBELO_CANADA_MARKETPLACE_REGIONAL_TAX: Record<string, number> = {
  ON: 0.13,
  NB: 0.15,
  NL: 0.15,
  NS: 0.15,
  PEI: 0.15,
  AB: 0.05,
  NWT: 0.05,
  YT: 0.05,
  NU: 0.05,
  QC: 0.14975,
  BC: 0.12,
  SK: 0.11,
  MB: 0.12,
  USA: 0,
  CB: 0,
};

export enum ReebeloBatteryHealth {
  BH_80 = '80',
  BH_90 = '90',
  BH_100 = '100',
}

export const SSMBUCKET_ES_SEARCH = 'es-search';
export const SSMBUCKET_AFTERSHIP = 'aftership';
export const SSMBUCKET_SHIPPING = 'shipping';
export const SSMBUCKET_ADLUCENT = 'ad-lucent';
export const SSMBUCKET_SLACK = 'slack';
export const SSMBUCKET_ZENDESK = 'zendesk';
export const SSMBUCKET_GOOGLE = 'google';
export const SSMBUCKET_MS_ENDPOINT = 'ms-endpoint';
export const SSMBUCKET_COBALT = 'cobalt';
export const SSMBUCKET_FEEDS = 'feeds';
export const SSMBUCKET_BRAZE = 'braze';
export const SSMBUCKET_METABASE = 'metabase';
export const SSMBUCKET_MS_SHIPPING = 'ms_shipping';

export type SSMBucket =
  | 'reebelo-dev'
  | 'reebelo-au'
  | 'reebelo-kr'
  | 'reebelo-nz'
  | 'quista'
  | 'reebelo-tw'
  | 'reebelo-us'
  | 'reebelo-ca'
  | 'es-search'
  | 'slack'
  | 'zendesk'
  | 'google'
  | 'auth'
  | 'aftership'
  | 'shipping'
  | 'ad-lucent'
  | 'cobalt'
  | 'feeds'
  | 'braze'
  | 'metabase'
  | typeof SSMBUCKET_MS_ENDPOINT
  | typeof SSMBUCKET_MS_SHIPPING;

export enum RC_DURATION {
  '24_MONTH' = '24',
  '13_MONTH' = '13',
  '12_MONTH' = '12',
}
