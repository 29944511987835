import { useState } from 'react';
import Image from 'next/future/image';
import cn from 'classnames';
import { ReebeloStoreT } from '@lambda/reebelo';
import infoIconBlack from '@/public/icons/product/infoIconBlack.svg';
import { Props } from './types';
import { priceFormater } from '@/settings/index';
import tickBlackIcon from '@/public/icons/reebelocare/tick_black.svg';
import shieldIcon from '@/public/icons/buyback/sell-device/shield.svg';
import reebeloCareIcon from '@/public/icons/product/reebeloCareIcon.svg';
import settings from '@/settings';

const storeDetails: Partial<Record<ReebeloStoreT, string[]>> & {
  default: string[];
} = {
  'reebelo-us': [
    'Complete Accidental Damage & Breakdown Cover inc. Liquid Spills',
    'Access to >1,000 Retail Locations for on-demand repair',
    "Express Replacement service if we can't repair your device",
    '24/7 Support',
  ],
  'reebelo-au': [
    'Request product servicing or replacement, for any reason.',
    'On-demand support, even if your product is faulty, damaged or broken.',
    'Your choice of walk-in, mail-in and come-to-you service (<a href="https://edge.reebelo.com/files/sqt/01-PDS.pdf" class="text-blue-500 underline" target="_blank" rel="noopener noreferrer">service fees apply</a>).',
    '24/7 online self-service available or call us in AU business hours.',
  ],
  quista: [
    'Worldwide Protection Against Physical & Liquid Damage',
    'Screen and Battery Replacement Included',
    '24-Months Extended Warranty',
    'Hassle Free: Mail-in or Walk-in Repair Options Nationwide',
  ],
  'reebelo-nz': [
    'Worldwide Protection Against Physical & Liquid Damage',
    'Screen and Battery Replacement Included',
    '24-Months Extended Warranty',
    'Hassle Free: Mail-in or Walk-in Repair Options Nationwide',
  ],
  'reebelo-ca': [
    'Covers accidental damage (inc.drops, spills and battery) plus product breakdowns.',
    'Screen, back glass & battery replacement included.',
    'Fast Repairs or Replacements.',
    'Fast and easy service with online claims + over-the-phone support.',
  ],
  default: [
    'Complete Accidental Damage & Breakdown Cover',
    'Access to >1,000 Retail Locations for on-demand repair',
    "Express Replacement service if we can't repair your device",
    '24/7 Support',
  ],
};
const reebelocareProtectionTitle: Partial<Record<ReebeloStoreT, string>> & {
  default: string;
} = {
  'reebelo-au': 'Worldwide, Any Reason Care for 24-Months',
  'reebelo-ca': '24-Months Protection by Allstate',
  default: 'Worldwide Protection for 24-Months',
};

const Recommended = ({
  className,
  price,
  onClick,
  isActive,
}: {
  className?: string;
  price: number;
  onClick: () => void;
  isActive: boolean;
}) => (
  <div
    className={cn(
      'flex flex-1 flex-col-reverse items-end justify-between gap-2 xxs:mb-2 xxs:flex-row xxs:items-center',
      className,
    )}
  >
    <span className="w-fit rounded bg-[#116A43] px-2 py-1 text-xxs font-bold text-white">
      Recommended
    </span>

    <div className="flex items-center gap-2 justify-self-end">
      {priceFormater.long(price)}
      <button
        onClick={() => onClick()}
        className={cn(
          'z-10 flex h-6 w-12 items-center justify-end rounded-full px-0.5 transition-all duration-150 ease-in-out md:h-5 md:w-10',
          {
            'bg-[#A5BCB6]': !isActive,
            'bg-[#116A43]': isActive,
          },
        )}
      >
        <div
          className={cn(
            'h-5 w-5 rounded-full bg-white transition-all duration-150 ease-in-out md:h-4 md:w-4',
            {
              'mr-1 -translate-x-full': !isActive,
            },
          )}
        />
      </button>
    </div>
  </div>
);

const ReebeloCareIcon = () => (
  <div className="h-20 w-20 md:hidden">
    <Image
      src={reebeloCareIcon}
      height={50}
      width={50}
      alt="ReebeloCare"
      className="h-full w-full object-cover"
    />
  </div>
);

const DeviceImage = ({ imageUrl }: { imageUrl: string }) => (
  <div className="hidden h-[75px] w-[75px] md:block">
    <div className="relative h-full w-full">
      <Image
        src={imageUrl}
        height={300}
        width={300}
        alt="Product"
        className="h-full w-full object-contain mix-blend-multiply"
      />
      <div className="absolute -top-4 -right-3 h-12 w-12">
        <Image
          src={shieldIcon}
          height={50}
          width={50}
          alt="ReebeloCare"
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  </div>
);

const Details = () => {
  const generateContent = () =>
    storeDetails[settings.store] ?? storeDetails.default;

  const content = generateContent();

  return (
    <div className="mt-3 flex flex-col gap-y-1">
      {content.map((item) => (
        <div className="flex gap-1" key={item}>
          <div className="mt-0.5 h-2.5 w-2.5 shrink-0">
            <Image
              src={tickBlackIcon}
              alt="Tick"
              width={15}
              height={15}
              className="h-full w-full object-contain"
            />
          </div>

          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: item }}
          />
        </div>
      ))}
    </div>
  );
};

export default function AdditionalItem({
  price,
  isActive,
  onClick,
  imageUrl,
  Modal,
}: Readonly<Props>) {
  const [isModalVisible, setModalVisibility] = useState(false);
  const isAUStore = settings.store === 'reebelo-au';

  const generateReebelocareProtection = () =>
    reebelocareProtectionTitle[settings.store] ??
    reebelocareProtectionTitle.default;

  const reebelocareProtection = generateReebelocareProtection();

  const hideModal = () => setModalVisibility(false);

  const showModal = () => {
    setModalVisibility(true);
  };

  const sectionTitle = isAUStore ? 'Add ReebeloCare' : 'Add a Protection Plan';

  return (
    <section>
      {/* Section Header  */}
      <button className="flex items-center gap-1.5" onClick={() => showModal()}>
        <h4 className="mb-1 flex text-xxs font-bold uppercase text-gray-700 lg:font-normal">
          {sectionTitle}
        </h4>
        <div className="-mt-1.5 h-3 w-3">
          <Image
            src={infoIconBlack}
            alt="Condition"
            width={12}
            height={12}
            className="h-full w-full object-contain"
          />
        </div>
      </button>
      {/* Section Card  */}
      <div
        className={cn(
          'relative mt-1 flex gap-3 rounded-lg border text-xs transition duration-150 ease-in-out',
          {
            'p-3': !isAUStore,
            'p-6 pr-3 md:p-6 md:pr-3 lg:p-3 xl:p-6 xl:pr-3': isAUStore,
          },
        )}
        // eslint-disable-next-line react/forbid-dom-props
        style={{
          background:
            'linear-gradient(90deg, #ECFCF5 0%, #ECFCF5 50%, #FFF 100%)',
        }}
      >
        <div className="flex w-full items-center gap-5">
          <DeviceImage imageUrl={imageUrl} />
          <div className="flex-1">
            <div className="flex w-full items-center gap-3">
              <ReebeloCareIcon />
              <div className="flex-1">
                <Recommended
                  isActive={isActive}
                  onClick={onClick}
                  price={price}
                />
                <h4 className="hidden text-sm font-bold xxs:block md:text-xs">
                  ReebeloCare: {reebelocareProtection}
                </h4>
              </div>
            </div>
            <h4 className="mt-2 text-sm font-bold xxs:hidden md:text-xs">
              ReebeloCare: {reebelocareProtection}
            </h4>
            <Details />
          </div>
        </div>
      </div>
      {Modal && (
        <Modal
          isVisible={isModalVisible}
          hideModal={hideModal}
          warrantyPurchased={isActive}
          purchaseWarranty={onClick}
          price={price}
        />
      )}
    </section>
  );
}
