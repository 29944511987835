import { useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import {
  RC_DURATION,
  REEBELO_CURRENCY,
  ReebeloBatteryHealth,
} from '@lambda/reebelo';
import {
  Cart,
  CartLine,
  Image as ProductImage,
} from '@lambda/commons/apis/shopify/types/storefront';
import { AppCtx } from 'pages/_app';
import { CtaButton } from '@/components/Cta/Cta';
import Loading from '@/components/Loading/Loading';
import settings from '@/settings';
import useShopify from '@/lib/use-shopify';
import { useVendorDispatch } from '@/lib/use-vendor-details';
import useCart, {
  generateIDAttr,
  encodeDecodeForAttr,
  generateAddCartDateTimeAttr,
  generateCategoryAttr,
} from '@/lib/use-cart';
import i18n from '@/settings/i18n';
import GTM from '@/lib/google-tag-manager';
import { ReplacedProduct } from '@/lib/collections/catalogue/helpers';
import { ShippingData } from '../collections/[handle]/NewProductPage/types';
import {
  trackAddToCart,
  trackReebeloCareAdded,
} from '@/lib/analytics/trackProduct';
import { getBatteryHealthFromOfferTag } from '../collections/[handle]/NewProductPage/BatteryUpsell/helper';
import { ProductSource, ReebeloCareTrackRequest } from '@/lib/analytics/types';
import useOfferUppricerMeta from '@/lib/use-offer-uppricer-meta';

const t = i18n.init();

export type ProductDetails = {
  id: string;
  name: string;
  price: string;
  brand: string;
  color?: string;
  variant: string;
  vendor?: string;
  category: string;
  condition?: string;
  comparePrice?: number;
  sku?: string;
  psku?: string;
  storage?: string;
  images?: ProductImage[];
};

const makePayloadFromOffer = (offer: ReplacedProduct) => {
  const currencyCode = REEBELO_CURRENCY[settings.store];
  const {
    title,
    key,
    productId,
    price,
    brand,
    categories,
    variantId,
    productType,
  } = offer;
  const eventPayload = {
    currencyCode,
    add: {
      products: [
        {
          price,
          brand,
          quantity: 1,
          name: title,
          id: key || productId,
          variant: variantId || '',
          category:
            categories && categories.length ? categories[0] : productType || '',
        },
      ],
    },
  };

  return eventPayload;
};

const makePayloadFromProductDetails = (details: ProductDetails) => {
  const currencyCode = REEBELO_CURRENCY[settings.store];
  const { price, brand, name, id, category, variant, storage } = details;
  const eventPayload = {
    currencyCode,
    add: {
      products: [
        {
          price,
          brand,
          quantity: 1,
          name,
          id,
          variant,
          category,
          storage,
        },
      ],
    },
  };

  return eventPayload;
};

const triggerTrackingEvents = ({
  offer,
  productDetails,
  isBatteryUpgradeSelected,
  batteryPriceDifference,
  productSource,
}: {
  offer?: ReplacedProduct;
  productDetails?: ProductDetails;
  source?: string;
  isBatteryUpgradeSelected?: boolean;
  batteryUpgradeSource?: 'Battery Upgrade Modal' | 'Product Page';
  batteryPriceDifference?: string;
  productSource?: ProductSource;
}) => {
  let payload;
  let itemVendor;

  if (offer !== undefined) {
    GTM.eventOffers('select_item', [offer]);
    payload = makePayloadFromOffer(offer);
    itemVendor = offer.vendor;
  } else if (productDetails !== undefined) {
    payload = makePayloadFromProductDetails(productDetails);
    itemVendor = productDetails.vendor;
  }

  if (!payload) return;

  GTM.event('addToCart', payload);

  const offerToTrack = offer;

  if (offerToTrack && isBatteryUpgradeSelected) {
    const batteryHealth = getBatteryHealthFromOfferTag(offerToTrack);

    if (
      isBatteryUpgradeSelected ||
      (batteryHealth && batteryHealth > ReebeloBatteryHealth.BH_80)
    ) {
      offerToTrack.batteryUpgradePct = batteryHealth;
      offerToTrack.batteryUpgradePrice = batteryPriceDifference ?? '0';
    }
  }

  trackAddToCart({
    offer: offerToTrack,
    details: productDetails,
    properties: { source: productSource, vendor: itemVendor },
  });
};

export const findItemInCart = (
  productId: string,
  cart: Cart | null,
): CartLine | undefined => {
  if (cart == null) return undefined;

  return cart.lines.edges.find(
    (lineItem) => lineItem.node.merchandise.id === productId,
  )?.node;
};

const findExpressShippingItemInCart = (
  idAttributeValue: string,
  cart: Cart | null,
): CartLine | undefined => {
  if (cart == null) return undefined;

  return cart.lines.edges.find(
    (lineItem) =>
      lineItem.node.attributes.some(
        (attr) => attr.key === 'ID' && attr.value === idAttributeValue,
      ) &&
      lineItem.node.merchandise.title
        .toLowerCase()
        .includes('express shipping'),
  )?.node;
};

const findExtendedWarrantyItemInCart = (
  idAttributeValue: string,
  cart: Cart | null,
): CartLine | undefined => {
  if (cart == null) return undefined;

  return cart.lines.edges.find(
    (lineItem) =>
      lineItem.node.attributes.some(
        (attr) => attr.key === 'ID' && attr.value === idAttributeValue,
      ) &&
      lineItem.node.merchandise.title
        .toLowerCase()
        .includes('extended warranty'),
  )?.node;
};

export default function PurchaseLinks(props: {
  variantId: number;
  productId: number;
  warranty: number | null;
  shippingData: ShippingData;
  deliverBy?: { min: string; max: string };
  subsidyTag?: string;
  isOutOfStock?: boolean;
  className?: string;
  cartButtonVariant?: 'dark' | 'outlined' | 'red';
  upgradation?: string;
  vendor: string;
  isDispatchableProd?: boolean;
  offer?: ReplacedProduct;
  productDetails?: ProductDetails;
  source?: string;
  category?: string;
  categoryPk?: string;
  comparePrice?: number;
  shouldRedirect?: boolean;
  children?: string;
  isBatteryUpgradeSelected?: boolean;
  batteryUpgradeSource?: 'Battery Upgrade Modal' | 'Product Page';
  batteryPriceDifference?: string;
  productSource?: ProductSource;
}) {
  const {
    shippingData,
    comparePrice,
    shouldRedirect = true,
    children,
    productSource = ProductSource.PDP,
  } = props;
  const isOutOfStock = props.isOutOfStock ?? false;
  const router = useRouter();
  const { pathname } = router;
  const dispatch = useVendorDispatch(props.vendor);
  const offerUppricerMeta = useOfferUppricerMeta({
    vendor: props.vendor,
    sku: props.offer?.sku,
  });
  const { cart: currentCart } = useContext(AppCtx);
  const cart = useCart();
  const [started, setStarted] = useState<null | 'add' | 'buy'>(null);
  const [, shopify] = useShopify();
  const isCart = pathname.endsWith('/cart');
  const { zipcodeStateData } = useContext(AppCtx);
  const useSubmit = useCallback(async () => {
    setStarted('add');

    const shopifyVariantId =
      props.productDetails?.id ||
      `gid://shopify/ProductVariant/${props.variantId}`;

    const response = await shopify({
      query: `query ($id: ID!) {
            product(id: $id) { 
              title
              variants(first:1){ edges { node { sku } 
            } } }
          }`,
      variables: { id: `gid://shopify/Product/${props.productId}` },
    });
    const variant = response.product?.variants.edges[0].node;
    const sku = variant?.sku || '';

    if (props.offer) {
      const offer = {
        ...props.offer,
        comparePrice,
      };

      // Multi Variant Product Page
      triggerTrackingEvents({
        offer,
        source: props.source,
        isBatteryUpgradeSelected: props.isBatteryUpgradeSelected,
        batteryUpgradeSource: props.batteryUpgradeSource,
        batteryPriceDifference: props.batteryPriceDifference,
        productSource,
      });
    }

    if (props.productDetails) {
      // Single Variant Product Page
      const productDetails = {
        ...props.productDetails,
        sku,
        comparePrice,
      };

      triggerTrackingEvents({
        productDetails,
        source: props.source,
        productSource,
      });
    }

    const itemInCart = findItemInCart(shopifyVariantId, currentCart);
    const bundleIdAttribute = generateIDAttr(props.vendor, sku);
    const expressShippingForItemInCart = findExpressShippingItemInCart(
      bundleIdAttribute[0].value,
      currentCart,
    );
    const extendedWarrantyForItemInCart = findExtendedWarrantyItemInCart(
      bundleIdAttribute[0].value,
      currentCart,
    );
    // it is to fix an edge case where when adding item for second time without ES, it will reset ES
    // related attributes
    const calibratedShippingData =
      expressShippingForItemInCart == null
        ? shippingData
        : ({
            type: 'express',
            value: Number(
              itemInCart?.attributes.find(
                (attr) => attr.key === '_shipping_option_cost',
              )?.value ?? 0,
            ),
            variantId: 0,
            minDeliveryDate:
              itemInCart?.attributes.find((attr) => attr.key === '_deliver_min')
                ?.value ?? '',
            maxDeliveryDate:
              itemInCart?.attributes.find((attr) => attr.key === '_deliver_max')
                ?.value ?? '',
            dispatch: {
              min:
                itemInCart?.attributes.find(
                  (attr) => attr.key === '_dispatch_min',
                )?.value ?? 1,
              max:
                itemInCart?.attributes.find(
                  (attr) => attr.key === '_dispatch_max',
                )?.value ?? 1,
            },
          } as ShippingData);

    const addWarrantyOrExpressShippingToCart = async (
      variantId: number,
      offer?: ReplacedProduct,
      type?: 'express',
    ) => {
      const cartId = await cart.getOrCreateCartId();
      const category = offer?.categories?.length ? offer.categories[0] : '';

      await cart.add(
        {
          merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
          attributes: [
            ...encodeDecodeForAttr.encode({
              sku,
              title: response.product?.title || '',
            }),
            { key: '_offer_category', value: category },
            ...generateIDAttr(props.vendor, sku),
            ...generateAddCartDateTimeAttr(),
            ...(type !== 'express'
              ? [{ key: 'Duration', value: RC_DURATION['24_MONTH'] }]
              : []),
          ],
        },
        cartId,
      );
    };

    // NOTE: We don't need to add RC if it already exists, quantity will be taken care of in cart.
    if (props.warranty != null && !extendedWarrantyForItemInCart) {
      const trackingProps: ReebeloCareTrackRequest = {
        entry_point: 'Product Page',
        reebelocare_id: props.warranty,
        device_psku: props.offer?.psku ?? '',
        device_vendor: props.offer?.vendor ?? '',
        device_sku: props.offer?.sku ?? '',
        duration: RC_DURATION['24_MONTH'],
      };

      await addWarrantyOrExpressShippingToCart(props.warranty, props.offer);
      trackReebeloCareAdded(trackingProps);
    }

    // NOTE: for this part we don't need to use calibratedShippingData because in the cart page it will be taken care of
    if (shippingData.type === 'express' && !expressShippingForItemInCart) {
      await addWarrantyOrExpressShippingToCart(
        shippingData.variantId,
        props.offer,
        shippingData.type,
      );
    }

    const upgradationAttributes = props.upgradation
      ? [
          {
            key: t`Free upgrade from`,
            value: props.upgradation.replace('Very Good', 'Good'),
          },
        ]
      : [];
    const subsidyAttributes =
      props.subsidyTag != null
        ? [
            {
              key: '_subsidy',
              value: props.subsidyTag,
            },
          ]
        : [];
    const shippingAttributes = [
      { key: '_shipping_option', value: calibratedShippingData.type },
      {
        key: '_shipping_option_cost',
        value: calibratedShippingData.value.toString(),
      },
    ];
    const deliverByAttributes =
      props.deliverBy && props.deliverBy.min !== ''
        ? [
            {
              key: '_deliver_min',
              value:
                calibratedShippingData.type === 'express'
                  ? calibratedShippingData.minDeliveryDate
                  : props.deliverBy.min,
            },
            {
              key: '_deliver_max',
              value:
                calibratedShippingData.type === 'express'
                  ? calibratedShippingData.maxDeliveryDate
                  : props.deliverBy.max,
            },
            {
              key: '_cutoff_time',
              value:
                shippingData.cutoffTime === '' ||
                shippingData.cutoffTime == null
                  ? ''
                  : shippingData.cutoffTime,
            },
            {
              key: '_cutoff_timezone',
              value:
                shippingData.cutoffTimezone === '' ||
                shippingData.cutoffTimezone == null
                  ? ''
                  : shippingData.cutoffTimezone,
            },
            ...(['reebelo-au', 'reebelo-nz', 'reebelo-ca'].includes(
              settings.store,
            )
              ? [
                  {
                    key: '_deliver_state',
                    value: zipcodeStateData?.state.toString() || '',
                  },
                  {
                    key: '_deliver_zipcode',
                    value: zipcodeStateData?.zipcode.toString() || '',
                  },
                ]
              : []),
          ]
        : [];
    const vendorDispatchAttributes =
      dispatch && !props.isDispatchableProd
        ? [
            {
              key: '_dispatch_min',
              value:
                calibratedShippingData.type === 'express'
                  ? calibratedShippingData.dispatch.min.toString()
                  : dispatch.min.toString(),
            },
            {
              key: '_dispatch_max',
              value:
                calibratedShippingData.type === 'express'
                  ? calibratedShippingData.dispatch.max.toString()
                  : dispatch.max.toString(),
            },
          ]
        : [];

    const cartId = await cart.getOrCreateCartId();

    const comparePriceAttributes = comparePrice
      ? [
          {
            key: '_compare_price',
            value: comparePrice.toString(),
          },
        ]
      : [];

    const batteryHealth = getBatteryHealthFromOfferTag(props.offer);

    const batteryHealthAttributes =
      batteryHealth &&
      Number(batteryHealth) > Number(ReebeloBatteryHealth.BH_80)
        ? [
            {
              key: '_battery_upgrade_pct',
              value: String(getBatteryHealthFromOfferTag(props.offer)),
            },
            {
              key: '_battery_upgrade_price',
              value: String(props.batteryPriceDifference),
            },
          ]
        : [];
    const offerUppricerMetaAttributes = [
      offerUppricerMeta?.upPricerRule != null && {
        key: '_uppricer',
        value: offerUppricerMeta.upPricerRule,
      },
      offerUppricerMeta?.upPriceAmount != null && {
        key: '_uppricer_total_amount',
        value: offerUppricerMeta.upPriceAmount.toString(),
      },
      offerUppricerMeta?.priceBeforeUpPricer != null && {
        key: '_uppricer_pre_upprice',
        value: offerUppricerMeta.priceBeforeUpPricer.toString(),
      },
      offerUppricerMeta?.priceAfterUpPricer != null && {
        key: '_uppricer_post_upprice',
        value: offerUppricerMeta.priceAfterUpPricer.toString(),
      },
    ].filter(Boolean) as { key: string; value: string }[];

    const categoryPk = props?.categoryPk ?? props?.offer?.categoryPks?.[0];

    const categoryPkAttributes = categoryPk
      ? [
          {
            key: '_category_pk',
            value: categoryPk,
          },
        ]
      : [];

    if (itemInCart == null) {
      await cart.add(
        {
          merchandiseId: `gid://shopify/ProductVariant/${props.variantId}`,
          attributes: [
            ...comparePriceAttributes,
            ...upgradationAttributes,
            ...vendorDispatchAttributes,
            ...subsidyAttributes,
            ...deliverByAttributes,
            ...shippingAttributes,
            ...generateAddCartDateTimeAttr(),
            ...generateCategoryAttr(props.category),
            ...batteryHealthAttributes,
            ...offerUppricerMetaAttributes,
            ...categoryPkAttributes,
          ],
        },
        cartId,
      );
    } else {
      await cart.update(
        [
          {
            attributes: [
              ...comparePriceAttributes,
              ...upgradationAttributes,
              ...vendorDispatchAttributes,
              ...subsidyAttributes,
              ...deliverByAttributes,
              ...shippingAttributes,
              ...generateCategoryAttr(props.category),
              ...batteryHealthAttributes,
              ...offerUppricerMetaAttributes,
              ...categoryPkAttributes,
            ],
            id: itemInCart.id,
            quantity: itemInCart.quantity + 1,
          },
        ],
        cartId,
      );
    }

    if (shouldRedirect) {
      if (isCart) window.location.href = '/cart';

      return router.push('/cart');
    }

    return null;
  }, [
    props.offer,
    props.warranty,
    props.variantId,
    props.upgradation,
    props.isDispatchableProd,
    dispatch,
  ]);

  useEffect(() => {
    // after 3 seconds reset the started state to avoid infinite loading on cached page state.
    if (started) setTimeout(() => setStarted(null), 3000);
  }, [started]);

  const submit = useSubmit;

  if (children) {
    return (
      <button
        type="button"
        onClick={submit}
        className="h-8 rounded-lg border border-black px-3 font-bold transition duration-150 ease-in-out hover:bg-black hover:text-white"
      >
        {started === 'add' ? <Loading size="base" /> : children}
      </button>
    );
  }

  return (
    <div className={props.className}>
      <CtaButton
        id="e2e-add-to-cart"
        variant={props.cartButtonVariant ?? 'dark'}
        className={'rounded-md text-sm sm:text-base'}
        loading={started === 'add' || (dispatch === undefined && !isCart)}
        disabled={
          isOutOfStock ||
          started === 'buy' ||
          (dispatch === undefined && !isCart)
        }
        onClick={submit}
        data-product-id={props.productId}
        data-variant-id={props.variantId}
      >
        {isOutOfStock ? 'Sold Out' : 'Add to Cart'}
      </CtaButton>
    </div>
  );
}
