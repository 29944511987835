import Link from 'next/link';
import Image from 'next/future/image';
import { categoryNameToHandle } from '@lambda/reebelo/src/commonHelpers';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { generateCategoryPageUrl } from '@/components/category/helpers';
import { getEdgeUrl } from '@/lib/getEdgeUrl';
import { PopularCategory } from '@/settings/popular-categories/types';
import { urlWithUtm } from '../helpers';
import { PageSource } from '../types';

const Content = ({
  small,
  badge,
  categoryImage,
  title,
}: {
  small: boolean;
  badge?: string;
  categoryImage: string;
  title: string;
}) => (
  <div className="flex flex-col items-center">
    <div
      className={cn(
        'relative flex h-16 w-16 items-center justify-center rounded-full bg-teal-500/40 sm:h-20 sm:w-20 md:h-24 md:w-24',
        {
          'lg:h-24 lg:w-24 xl:h-28 xl:w-28': !small,
        },
      )}
    >
      {badge && (
        <div className="absolute left-0 top-0 rounded-full bg-gray-700 px-2 py-1 text-[10px] text-white">
          {badge}
        </div>
      )}
      <div className="absolute top-0 h-full w-full">
        <Image
          width={200}
          height={200}
          className="h-full w-full object-contain object-right"
          src={categoryImage}
          alt={title}
          quality={100}
        />
      </div>
    </div>
    <h3 className="mt-3 w-full truncate text-center text-sm">{title}</h3>
  </div>
);

const Card = ({
  category,
  small = false,
}: {
  category: PopularCategory;
  small?: boolean;
}) => {
  const router = useRouter();
  const { pathname } = router;
  const { badge, handle, pk, title, imageUrl } = category;

  const link = handle
    ? `/collections/${handle}`
    : generateCategoryPageUrl(title, pk as string);

  const categoryImage =
    imageUrl ??
    getEdgeUrl(
      `images/homepage/popular-categories-v2/${categoryNameToHandle(
        title,
      )}.png`,
    );

  if (badge && badge === 'Soon') {
    return (
      <Content
        small={small}
        badge={badge}
        categoryImage={categoryImage}
        title={title}
      />
    );
  }

  // Add utm tags to the url if the user is on the thank you page
  const generateUrl = (_url: string) => {
    if (pathname.includes('thank-you'))
      return urlWithUtm(_url, PageSource.THANK_YOU_PAGE);

    return _url;
  };
  const url = generateUrl(link);

  return (
    <Link href={url} key={handle ?? pk}>
      <a>
        <Content
          small={small}
          badge={badge}
          categoryImage={categoryImage}
          title={title}
        />
      </a>
    </Link>
  );
};

export default Card;
