import { Customer, CustomerAddress } from '@lambda/apis/src/customer/types';
import { DefaultValuesOption } from '@stripe/stripe-js';
import { CartLine } from '@lambda/commons/apis/shopify/types/storefront';
import { formatPhoneNumber } from '@lambda/apis/src/customer/helpers';
import dayjs from 'dayjs';
import { CheckoutDiscountT, CheckoutT } from '@lambda/apis/src/checkout/types';
import { NAVIGATION } from './constants';
import settings from '@/settings';
import { deleteCartLines, updateCartLines } from '../cart';
import { UpdateCartParams } from '../use-cart';
import { CheckoutStep } from './enums';
import { logger } from '../logger';

export const allowedCountries =
  settings.store !== 'reebelo-dev' ? [settings.country_code] : undefined;

/**
 * Phone number is optional for addresses
 * @param address
 * @returns
 */

const restrictedStates = {
  AK: 'Alaska',
  AS: 'American Samoa',
  AE: 'Armed Forces Africa',
  AA: 'Armed Forces Americas',
  AP: 'Armed Forces Pacific',
  MP: 'Commonwealth of the Northern Mariana Islands',
  FM: 'Federated States of Micronesia',
  GU: 'Guam',
  HI: 'Hawaii',
  PR: 'Puerto Rico',
  PW: 'Republic of Palau',
  MH: 'Republic of the Marshall Islands',
  VI: 'US Virgin Islands',
};

export enum AddressValidationErrorMessages {
  RESTRICTED_STATE = 'Sorry. We don’t deliver to your address yet. Please try a different address',
  PO_BOX = 'Unfortunately, we don’t deliver to PO box addresses. Please try with a different address.',
  DEFAULT = 'Please provide the missing or correct information to complete the order.',
}

export const handleUSRestrictedStates = (state: string | undefined) => {
  if (
    (settings.store === 'reebelo-dev' || settings.store === 'reebelo-us') &&
    state &&
    restrictedStates[state as keyof typeof restrictedStates]
  )
    return AddressValidationErrorMessages.RESTRICTED_STATE;

  return null;
};

const poBoxPatterns = [
  /p\.?\s*o\.?\s*box/i, // P.O. Box, PO Box, P O Box
  /post\s*office\s*box/i, // Post Office Box
  /p\.?\s*o\.?\s*b\.?\s*#?\s*\d+/i, // P.O.B. #123, POB 123
  /box\s*#?\s*\d+/i, // Box #123, Box 123
  /bo[iî]te\s*postale/i, // Boîte Postale
  /^#?\s*\d+\s*p\.?\s*o\.?\s*b/i, // #123 POB
  /^p\.?\s*o\.?\s*b\.?\s*\d+/i, // POB 123
  /^bp\s*\d+/i, // BP 123
  /[\u2119][\u24DE]\s*box/i, // Unicode P O Box
];

export const isPOBox = (address?: CustomerAddress): boolean => {
  // Verify address object and normalize address lines
  if (!address || typeof address !== 'object') return false;

  const cleanAddress1 = String(address.address1 || '')
    .trim()
    .normalize('NFKC');
  const cleanAddress2 = String(address.address2 || '')
    .trim()
    .normalize('NFKC');

  // Test both address lines against all patterns
  return poBoxPatterns.some(
    (pattern) => pattern.test(cleanAddress1) || pattern.test(cleanAddress2),
  );
};

export const isAddressValid = (
  address?: CustomerAddress | undefined,
): { valid: boolean; errorMessage: AddressValidationErrorMessages | null } => {
  const state = address?.state;

  if (
    ['reebelo-us', 'reebelo-dev'].includes(settings.store) &&
    state &&
    restrictedStates[state as keyof typeof restrictedStates]
  ) {
    return {
      valid: false,
      errorMessage: AddressValidationErrorMessages.RESTRICTED_STATE,
    };
  }

  if (
    ['reebelo-us', 'reebelo-ca', 'reebelo-dev'].includes(settings.store) &&
    isPOBox(address)
  ) {
    return {
      valid: false,
      errorMessage: AddressValidationErrorMessages.PO_BOX,
    };
  }

  let valid = !!(
    address &&
    address.firstName &&
    address.lastName &&
    address.address1 &&
    address.city &&
    address.country &&
    address.postalCode &&
    address.state &&
    address.phone
  );

  if (['reebelo-nz'].includes(settings.store)) {
    // NZ doesn't have states
    valid = !!(
      address &&
      address.firstName &&
      address.lastName &&
      address.address1 &&
      address.city &&
      address.country &&
      address.postalCode &&
      address.phone
    );
  }

  if (['quista'].includes(settings.store)) {
    // SG doesn't have states or city validation
    valid = !!(
      address &&
      address.firstName &&
      address.lastName &&
      address.address1 &&
      address.country &&
      address.postalCode &&
      address.phone
    );
  }

  logger.debug({ address, valid }, 'Determined if address is valid');

  return {
    valid,
    errorMessage: !valid ? AddressValidationErrorMessages.DEFAULT : null,
  };
};

/**
 * Phone number and address2 are optional for addresses
 * @param address1
 * @param address2
 * @returns
 */
export const addressesAreEqual = (
  address1: CustomerAddress,
  address2: CustomerAddress,
) => {
  const equal =
    address1.firstName === address2.firstName &&
    address1.lastName === address2.lastName &&
    address1.address1 === address2.address1 &&
    address1.city === address2.city &&
    address1.country === address2.country &&
    address1.state === address2.state &&
    address1.postalCode === address2.postalCode &&
    (address1.phone === address2.phone || !address1.phone || !address2.phone) &&
    (address1.address2 === address2.address2 ||
      !address1.address2 ||
      !address2.address2);

  logger.debug(
    { address1, address2, equal },
    'Determined if addresses are equal',
  );

  return equal;
};

export const generatePageTitle = (
  step?: CheckoutStep,
  error?: string | null,
  processing?: boolean,
) => {
  if (!step) return `Loading - Reebelo ${settings.country_code} - Checkout`;

  const currentStepLabel = NAVIGATION.find((nav) => nav.value === step)?.label;

  return `${processing ? 'Processing - ' : ''} ${
    error != null ? 'Error - ' : ''
  } ${currentStepLabel} - Reebelo ${settings.country_code} - Checkout`;
};

export const generateDefaultValuesOption = (
  customer: Customer,
): DefaultValuesOption => ({
  billingDetails: {
    name: `${customer.billingAddress?.firstName} ${customer.billingAddress?.lastName}`,
    email: customer.email,
    phone: customer.billingAddress?.phone,
    address: {
      country: customer.billingAddress?.country,
      postal_code: customer.billingAddress?.postalCode,
      state: customer.billingAddress?.state,
      city: customer.billingAddress?.city,
      line1: customer.billingAddress?.address1,
      line2: customer.billingAddress?.address2 || undefined,
    },
  },
});

export const generateLineItemQuantity = (
  lineItem: CartLine,
  outOfStockLineItems: CartLine[],
) => {
  const title = lineItem.merchandise.product.title.toLowerCase();

  if (title.includes('reebelocare') || title.includes('express shipping')) {
    const productFor = lineItem.attributes.find(
      (attr) => attr.key === 'For',
    )?.value;

    if (productFor) {
      const matchedProduct = outOfStockLineItems.find(
        (item) =>
          `[${item.merchandise.sku}] ${item.merchandise.product.title}` ===
          productFor,
      );

      if (matchedProduct != null) {
        return [
          matchedProduct.quantity,
          matchedProduct.merchandise.quantityAvailable,
        ];
      }
    }
  }

  return [lineItem.quantity, lineItem.merchandise.quantityAvailable];
};

export const updateCartLineItems = async (
  lineItems: CartLine[],
  cartId: string,
) => {
  const toBeRemoved: string[] = [];
  const toBeUpdated: UpdateCartParams = [];

  lineItems.forEach((lineItem) => {
    const [, availableQuantity] = generateLineItemQuantity(lineItem, lineItems);

    if (availableQuantity < 1) {
      toBeRemoved.push(lineItem.id);
    } else {
      const updatedLineItem = {
        attributes: lineItem.attributes,
        id: lineItem.id,
        quantity: availableQuantity,
      };

      toBeUpdated.push(updatedLineItem);
    }
  });

  await updateCartLines(toBeUpdated, cartId);
  await deleteCartLines(toBeRemoved, cartId);
};

export const getPaypalPhoneNumber = (
  payerCountryCode: string | undefined,
  nationalNumber: string | undefined,
) => {
  if (!nationalNumber || !payerCountryCode) return undefined;

  return formatPhoneNumber(nationalNumber, payerCountryCode);
};

export const findDeliveryRange = (
  dates: { deliverMin?: string; deliverMax?: string }[],
): { earliestMin?: string; latestMax?: string } => {
  let earliestMin: dayjs.Dayjs | undefined;
  let latestMax: dayjs.Dayjs | undefined;

  dates.forEach(({ deliverMin, deliverMax }) => {
    if (deliverMin) {
      const minDate = dayjs(deliverMin);

      if (!earliestMin || minDate.isBefore(earliestMin)) earliestMin = minDate;
    }

    if (deliverMax) {
      const maxDate = dayjs(deliverMax);

      if (!latestMax || maxDate.isAfter(latestMax)) latestMax = maxDate;
    }
  });

  return {
    earliestMin: earliestMin?.format('DD MMM'),
    latestMax: latestMax?.format('DD MMM'),
  };
};

export const getUniqueDiscountCodes = (
  checkout: CheckoutT,
): CheckoutDiscountT[] => {
  const discounts = (checkout.lineItems || [])
    .filter((line) => line.discounts?.length)
    .flatMap((line) => line.discounts)
    .concat(checkout.discounts)
    .filter((discount) => discount?.applicable);

  return discounts.reduce((acc, discount) => {
    if (!discount?.code) return acc;

    const existingDiscount = acc.find(
      (d) => d?.code && d.code === discount.code,
    );

    if (existingDiscount) existingDiscount.amount += discount.amount;
    else acc.push({ ...discount });

    return acc;
  }, [] as CheckoutDiscountT[]);
};
