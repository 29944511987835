import Image from 'next/future/image';
import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { extractTags, extractVariant } from '@lambda/reebelo/src/tagHelpers';
import { pickBy, omit } from 'lodash-es';
import { SearchOffers } from '@lambda/commons/apis/elasticsearch/types';
import useSWR from 'swr';
import { ReebeloConditions } from '@lambda/reebelo';
import cn from 'classnames';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { AppCtx } from '../../../pages/_app';
import { DispatchType } from '@/lib/use-vendor-details';
import i18n from '@/settings/i18n';
import chevronRight from '@/public/icons/product/chevronRight.svg';
import { ReplacedProduct } from '@/lib/collections/catalogue/helpers';
import DeliveryByPopup from '@/components/commons/Navbar/AccessBar/DeliveryByPopup';
import { ZipcodeStateData } from '@/lib/use-customer-zipcode';
import {
  formatDeliveryCutOffTime,
  formatDeliveryDateRange,
} from '@/lib/use-delivery-date';
import { priceFormater } from '@/settings';
import OtherOffers, { OfferWithDeliveryAndShippingInfo } from './OtherOffers';
import type { DeliveryDateResponse } from '@/api/products/delivery-date';
import type {
  ExpressShippingOfferResponse,
  ExpressShippingShopifyOfferResponse,
} from '@/api/express-shipping/offer';
import { ShippingData } from './NewProductPage/types';
import { logger } from '@/lib/logger';
import { trackProductFasterDeliveryViewed } from '@/lib/analytics/trackProduct';

dayjs.extend(isSameOrAfter);

const t = i18n.init();

const DisplayDeliveryCutOffTime = ({
  cutoffTime,
}: {
  cutoffTime: string | null;
}) => {
  if (cutoffTime == null) return null;

  return (
    <div>
      Order before <span className="font-bold">{cutoffTime}</span>
    </div>
  );
};

const DeliveryOptions = ({
  dispatch,
  deliveryDate,
  expressShippingOffer,
  onShippingTypeChanged,
}: {
  dispatch: DispatchType;
  deliveryDate?: DeliveryDateResponse;
  expressShippingOffer?: ExpressShippingShopifyOfferResponse;
  onShippingTypeChanged: (data: ShippingData) => void;
}) => {
  const standardDeliveryDateFormatted = formatDeliveryDateRange(
    deliveryDate?.standard,
    'short',
  );
  const standardDeliveryCutOffFormated = formatDeliveryCutOffTime(
    deliveryDate?.standard,
  );
  const expressDeliveryDateFormatted = formatDeliveryDateRange(
    deliveryDate?.express,
    'short',
  );
  const shouldShowExpressShippingOption =
    expressDeliveryDateFormatted !== '' && expressShippingOffer != null;

  const expressDeliveryCutOffFormated = formatDeliveryCutOffTime(
    deliveryDate?.express,
  );

  if (shouldShowExpressShippingOption) {
    return (
      <div className="flex flex-col">
        <div className="flex flex-wrap-reverse items-center justify-between gap-3">
          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <label htmlFor="standard" className="custom-radio small-version">
            <input
              type="radio"
              id="standard"
              name="shipping"
              value="standard"
              defaultChecked={true}
              onChange={() =>
                onShippingTypeChanged({
                  type: 'standard',
                  value: 0,
                  cutoffTime: deliveryDate?.standard.cutoffTime || '',
                  cutoffTimezone: deliveryDate?.standard.cutoffTimezone || '',
                })
              }
            />
            <span className="font-bold uppercase text-[#0DA864]">Free</span>{' '}
            delivery by{' '}
            <span className="font-bold">{standardDeliveryDateFormatted}</span>
            <DisplayDeliveryCutOffTime
              cutoffTime={standardDeliveryCutOffFormated}
            />
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="mt-4 flex flex-wrap-reverse items-center justify-between gap-3">
          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <label htmlFor="express" className="custom-radio small-version">
            <input
              type="radio"
              id="express"
              name="shipping"
              value="express"
              onChange={() =>
                onShippingTypeChanged({
                  type: 'express',
                  value: Number(expressShippingOffer.price),
                  variantId: expressShippingOffer.variantId,
                  minDeliveryDate: deliveryDate?.express.minDeliveryDate || '',
                  maxDeliveryDate: deliveryDate?.express.maxDeliveryDate || '',
                  cutoffTime: deliveryDate?.express.cutoffTime || '',
                  cutoffTimezone: deliveryDate?.express.cutoffTimezone || '',
                  dispatch: expressShippingOffer.dispatch,
                })
              }
            />
            {priceFormater.short(expressShippingOffer.price)} Express delivery
            by <span className="font-bold">{expressDeliveryDateFormatted}</span>
            <DisplayDeliveryCutOffTime
              cutoffTime={expressDeliveryCutOffFormated}
            />
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
    );
  }

  if (standardDeliveryDateFormatted) {
    return (
      <div className="flex flex-wrap-reverse items-center justify-between gap-3">
        <div>
          <span className="font-bold uppercase text-[#0DA864]">Free</span>{' '}
          delivery by{' '}
          <span className="font-bold">{standardDeliveryDateFormatted}</span>
          <DisplayDeliveryCutOffTime
            cutoffTime={standardDeliveryCutOffFormated}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center">
        <span className="font-bold uppercase text-[#0DA864]">
          {t`Free`}&nbsp;
        </span>
        <span>{t` delivery`}</span>
        <span>&nbsp;|&nbsp;</span>
        {t`Dispatch in`}{' '}
        {dispatch.max !== 1
          ? `${dispatch.min}-${dispatch.max}`
          : `${dispatch.min}`}
        <span className="pl-1">{t`business days`}</span>
      </div>
    </>
  );
};

const getMoreOptionsText = ({
  bestOffer,
  otherOffers,
  selectedShippingData,
  deliveryDateData,
}: {
  bestOffer: OfferWithDeliveryAndShippingInfo | null;
  otherOffers: Array<OfferWithDeliveryAndShippingInfo>;
  selectedShippingData: ShippingData;
  deliveryDateData?: DeliveryDateResponse;
}) => {
  if (bestOffer == null || otherOffers.length === 0) return '';

  if (deliveryDateData == null)
    return `${otherOffers.length} more buying options`;

  const selectedDelivery =
    deliveryDateData.express.minDeliveryDate !== '' ? 'express' : 'standard';

  const fasterStandardDeliveryOtherOffer = otherOffers.find(
    (offerItem) =>
      offerItem.deliveryDate != null &&
      offerItem.deliveryDate.standard.minDeliveryDate !== '' &&
      offerItem.deliveryDate.standard.minDeliveryDate <
        (deliveryDateData as DeliveryDateResponse)[selectedDelivery]
          .minDeliveryDate,
  );
  const fasterExpressDeliveryOtherOffer = otherOffers.find(
    (offerItem) =>
      offerItem.deliveryDate != null &&
      offerItem.deliveryDate.express.minDeliveryDate !== '' &&
      offerItem.deliveryDate.express.minDeliveryDate <
        (deliveryDateData as DeliveryDateResponse)[selectedDelivery]
          .minDeliveryDate,
  );

  if (
    fasterExpressDeliveryOtherOffer != null &&
    fasterExpressDeliveryOtherOffer.deliveryDate != null &&
    fasterExpressDeliveryOtherOffer.expressShippingOffer != null
  ) {
    const formattedDeliveryDate = formatDeliveryDateRange(
      fasterExpressDeliveryOtherOffer.deliveryDate.express,
      'short',
    );
    const priceDifference =
      fasterExpressDeliveryOtherOffer.price -
      bestOffer.price +
      (selectedShippingData.type === 'standard'
        ? Number(fasterExpressDeliveryOtherOffer.expressShippingOffer.price)
        : 0);

    return `Faster delivery available - ${formattedDeliveryDate} ${
      priceDifference > 0 ? `(+${priceFormater.short(priceDifference)})` : ''
    }`;
  }

  if (
    fasterStandardDeliveryOtherOffer != null &&
    fasterStandardDeliveryOtherOffer.deliveryDate != null
  ) {
    const formattedDeliveryDate = formatDeliveryDateRange(
      fasterStandardDeliveryOtherOffer.deliveryDate.standard,
      'short',
    );
    const priceDifference =
      fasterStandardDeliveryOtherOffer.price - bestOffer.price;

    return `Faster delivery available - ${formattedDeliveryDate} ${
      priceDifference > 0 ? `(+${priceFormater.short(priceDifference)})` : ''
    }`;
  }

  return `${otherOffers.length} more buying options`;
};

const getDeliveryDateAndShippingOptionsForOffers = async ({
  offerList,
  zipcodeStateData,
  bestOffer,
  categoryPk,
}: {
  offerList: Array<SearchOffers>;
  zipcodeStateData: ZipcodeStateData | null;
  bestOffer: ReplacedProduct;
  categoryPk: string;
}) => {
  const promises = offerList.map(async (offerItem) => {
    const returnItem: OfferWithDeliveryAndShippingInfo = {
      ...offerItem,
      deliveryDate: null,
    };

    const deliveryResponse = await axios
      .get<DeliveryDateResponse>(
        `/api/products/delivery-date?zipcode=${
          zipcodeStateData?.zipcode
        }&state=${zipcodeStateData?.state}&category=${
          offerItem.categories?.[0]
        }&vendor=${encodeURIComponent(offerItem.vendor)}`,
      )
      .catch(() => ({ data: null }));
    const offerDeliveryDate = deliveryResponse.data;

    returnItem.deliveryDate = offerDeliveryDate;

    if (
      offerDeliveryDate != null &&
      offerDeliveryDate.express.minDeliveryDate !== ''
    ) {
      const shippingResponse = await axios
        .get<ExpressShippingOfferResponse>(
          `/api/express-shipping/offer?vendor=${encodeURIComponent(
            offerItem.vendor,
          )}&categoryPk=${categoryPk}`,
        )
        .catch(() => ({ data: null }));
      const offerExpressShipping = shippingResponse.data?.shopifyOffer;

      returnItem.expressShippingOffer = offerExpressShipping;
    }

    return returnItem;
  });
  const offersWithDeliveryData = await Promise.all(promises).catch((err) => {
    logger.error(err);

    return [];
  });

  const best = offersWithDeliveryData.find(
    (offerItem) => offerItem.key === bestOffer.key,
  );
  const others = offersWithDeliveryData.filter(
    (offerItem) => offerItem.key !== bestOffer.key,
  );

  return {
    best: best ?? null,
    others,
  };
};

export default function ShippingAndOtherOffer(props: {
  dispatch: DispatchType;
  offer: ReplacedProduct;
  productName: string;
  vendor: string;
  condition: string;
  deliveryDate?: DeliveryDateResponse;
  expressShippingOffer?: ExpressShippingShopifyOfferResponse;
  onShippingTypeChanged: (data: ShippingData) => void;
  categoryPk: string;
}) {
  const {
    dispatch,
    offer,
    productName,
    deliveryDate,
    expressShippingOffer,
    onShippingTypeChanged,
    categoryPk,
  } = props;
  const [showOthers, setShowOthers] = useState(false);
  const [isDeliverByOpen, setIsDeliverByOpen] = useState(false);
  const [shippingData, setShippingData] = useState<ShippingData>({
    type: 'standard',
    value: 0,
    cutoffTime: deliveryDate?.standard.cutoffTime || '',
    cutoffTimezone: deliveryDate?.standard.cutoffTimezone || '',
  });

  const [listOffers, setListOffers] = useState<Array<SearchOffers>>([]);
  const [best, setBest] = useState<OfferWithDeliveryAndShippingInfo | null>(
    null,
  );
  const [others, setOthers] = useState<Array<OfferWithDeliveryAndShippingInfo>>(
    [],
  );

  const [moreOptionsText, setMoreOptionsText] = useState<string>('');
  const { setZipcodeStateData, zipcodeStateData } = useContext(AppCtx);

  const handleShippingTypeChanged = (data: ShippingData) => {
    setShippingData(data);
    onShippingTypeChanged(data);
  };

  const skipShowingOffer = Object.keys(offer).length === 0;

  const variant = {
    ...omit(extractVariant(offer.tags), [
      'cellularStatus',
      'networkProvider',
      'cellularNetwork',
    ]),
    serviceProviders: offer.serviceProvider as string,
  };
  const qs = new URLSearchParams(pickBy(variant, (v) => v));
  const { data: offers } = useSWR<SearchOffers[]>(() => {
    if (skipShowingOffer) return null;

    return `/api/collections/other-offers?${qs}&moreOptions=true`;
  });

  qs.set('condition', offer.replacedFromCondition as string);
  const { data: replaceOffers } = useSWR<SearchOffers[]>(() => {
    if (skipShowingOffer) return null;

    return offer.replacedFromCondition
      ? `/api/collections/other-offers?${qs}&size=1&moreOptions=true`
      : null;
  });

  const getAndSetOffersWithDeliveryAndShipping = async (
    offerList: Array<SearchOffers>,
    customerZipcodeStateData: ZipcodeStateData | null,
  ) => {
    setListOffers(offerList);
    const result = await getDeliveryDateAndShippingOptionsForOffers({
      offerList,
      zipcodeStateData: customerZipcodeStateData,
      bestOffer: offer,
      categoryPk,
    });

    setBest(result.best);

    // Other offers should not include best offer + should only show the cheapest offer from the same vendor
    const filteredOthers = result.others.filter(
      (item, index) =>
        item.vendor !== result.best?.vendor &&
        result.others.findIndex((el) => el.vendor === item.vendor) === index,
    );

    setOthers(filteredOthers);
  };

  useEffect(() => {
    setMoreOptionsText(
      getMoreOptionsText({
        bestOffer: best,
        otherOffers: others,
        selectedShippingData: shippingData,
        deliveryDateData: deliveryDate,
      }),
    );
  }, [best, others, shippingData, deliveryDate]);

  useEffect(() => {
    getAndSetOffersWithDeliveryAndShipping(
      [...(replaceOffers || []), ...(offers || [])],
      zipcodeStateData,
    );
  }, [offers, replaceOffers, zipcodeStateData]);

  const specs = extractTags(offer.tags);
  const boxCss = 'border border-gray-200 p-4 text-xs xs:text-xs lg:text-sm';

  // translate condition explicitly, others variant will be translated in cobalt
  if (specs.condition !== undefined)
    specs.condition = t(specs.condition) as ReebeloConditions;

  const specifications: Record<string, unknown> = specs;

  // insert the service provider explicitly since it's not available in offer's tag
  if (offer.serviceProvider)
    specifications.serviceProvider = offer.serviceProvider;

  return (
    <>
      <div
        className={cn(
          'border',
          boxCss,
          {
            'rounded-t-md': others.length,
          },
          { 'rounded-md': !others.length },
        )}
        key={offer.productId}
      >
        <DeliveryByPopup
          isOpen={isDeliverByOpen}
          onClose={() => setIsDeliverByOpen(false)}
          onStateNameChange={(data: ZipcodeStateData) => {
            setZipcodeStateData(data);
          }}
        />
        <DeliveryOptions
          dispatch={dispatch}
          deliveryDate={deliveryDate}
          expressShippingOffer={expressShippingOffer}
          onShippingTypeChanged={handleShippingTypeChanged}
        />
      </div>
      {others.length > 0 && (
        <>
          <button
            className={cn(
              'relative flex h-full w-full cursor-pointer items-center rounded-b-md !border-t-0 text-left outline-0',
              boxCss,
            )}
            onClick={() => {
              setShowOthers(true);
              trackProductFasterDeliveryViewed(listOffers);
            }}
          >
            <div className="flex flex-wrap-reverse items-center gap-3">
              {moreOptionsText}
            </div>
            <div className="absolute right-4 top-1/2 w-[7px] -translate-y-1/2 xxs:right-5 xs:right-6 xs:w-2">
              <Image src={chevronRight} alt="expand" className="w-full" />
            </div>
          </button>
          <OtherOffers
            productName={productName}
            tags={offer.tags}
            show={showOthers}
            cheapest={best}
            others={others}
            specs={specs}
            onClose={() => setShowOthers(false)}
            categoryPk={categoryPk}
          />
        </>
      )}
    </>
  );
}
